
import Algolia from 'Base/classes/Algolia';
import Dispatch from 'Base/classes/Dispatch';
import Quote from 'Base/classes/Quote';

export default ({id, text, suggestions, workfield = null, professional = null}) => ({
    id: id,
    workfield: workfield,
    professional: professional,
    text: text,
    insuranceSelected : null,
    insurances: [],
    insurancesLoading : false,
    autocomplete: {
        focused: false,
        query: '',
        suggestions: suggestions,
        results: [],
    },
    elantis: false,
    loading: false,
    init()
    {
        this.loading = false;
        if(this.workfield)
        {
            this.fetchInsurances();
        }
    },
    isValid()
    {
        return this.id && this.workfield;
    },
    back()
    {
        new Dispatch(this, 'quote-previous-step', {step: 3});
    },
    save()
    {
        this.loading = true;

        new Dispatch(this, 'quote-next-step', {step: 3});

        new Quote(this.id).step3({
            workfield_id: this.workfield,
            text: this.text,
            elantis: this.elantis,
            insurances : this.insurances.map((insurance) => {
                return {
                    insurance_id : insurance.id,
                    is_accepted : insurance.id === this.insuranceSelected?.id
                }
            })
        });
    },
    getResults() {
        if ( this.autocomplete.query == '' ) {
            return this.autocomplete.suggestions;
        }
        return this.autocomplete.results;
    },
    searchWorkfields() {
        if ( ! this.autocomplete.query ) {
          return;
        }

        new Algolia('work_fields', window.current.search.attributes.workfield).get(this.autocomplete.query)
            .then(results => {
                this.autocomplete.results = results.slice(0, 10);

                if ( this.professional ) {
                    this.autocomplete.results = results.filter(result => this.autocomplete.suggestions.some(suggestion => suggestion.id == result.id)).slice(0, 10);
                }
            });
    },
    setWorkfield(workfield) {
        console.log(workfield);
        this.autocomplete.query = workfield.title;
        this.workfield = workfield.id;
        const url = new URL(window.location);
        url.searchParams.set('workfield', workfield.id);
        window.history.pushState({}, '', url);
        this.autocomplete.results = [];
        this.fetchInsurances();
    },

    fetchInsurances(){
        this.insurancesLoading = true;
        fetch(`${window.location.origin + '/' + window.location.pathname.split('/')[1]}/insurances?workfieldId=${this.workfield}`, {
            method: "get",
        }).then((response) => {
            if (!response.ok) {
                return response.json().then((data) => {
                    throw { status: response.status, data: data };
                });
            }
            return response.json();
        }).then((data) => {
            this.insurancesLoading = false
            this.insurances = data.data;
        }).catch((error) => {
            console.error("Erreur de requête :", error);
        });
    },

    selectInsurance(insurance)
    {
        const url = new URL(window.location);
        if(!insurance) {
            this.insuranceSelected = null;
            url.searchParams.set('insurance', 'null');
            window.history.pushState({}, '', url);
            return;
        };

        this.insuranceSelected = insurance;
        url.searchParams.set('insurance', insurance.id);
        window.history.pushState({}, '', url);
    },
    autocompleteFocus() {
        this.autocomplete.focused = true;
    },
    autocompleteBlur() {
        setTimeout(() => {
            this.autocomplete.focused = false;
        }, 200);
    }
});